import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useState } from 'react';
import WebFont from 'webfontloader';
import ConfigProvider from 'antd/es/config-provider';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import Calculator from './Calculator';
import Order from './Order';
import { BillingSubjectType, ContactType, PaymentMethod } from './types';
import { isOrderAllowed } from './utils';
import { getGlobals } from './api';
import Card from 'antd/es/card';
import Typography from 'antd/es/typography';

dayjs.extend(duration);

enum ActiveComponent {
  'calculator' = 'calculator',
  'order' = 'order',
  'offline' = 'offline',
  'success' = 'success',
}

interface UseKeyboardShortcutArgs {
  key: string
  onKeyPressed: () => void;
}

export function useKeyboardShortcut({
  key,
  onKeyPressed
}: UseKeyboardShortcutArgs) {
  useEffect(() => {
    function keyDownHandler(e: globalThis.KeyboardEvent) {
      if (e.key === key) {
        e.preventDefault();
        onKeyPressed();
      }
    }

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);
}

type AppPropTypes = {
  shadowRoot: ShadowRoot, 
}

export function App({ shadowRoot }: AppPropTypes) {
  const [activeComponent, setActiveComponent] = useState('calculator');

  const onCalculatorSubmit = () => setActiveComponent('order');

  const [state, stateSet] = useState({
    isDebugEnabled: false,
    isOrderForced: false,
    isOrderAllowed: false,
  });

  const setState = (keyValues: any) => {
    stateSet({ ...state, ...keyValues });
  }

  useKeyboardShortcut({
    key: 'F15',
    onKeyPressed: () => {
      stateSet((prevState) => ({ 
        ...prevState,
        isDebugEnabled: !prevState.isDebugEnabled
      }));
    }
  });

  useKeyboardShortcut({
    key: 'F14',
    onKeyPressed: () => {
      stateSet((prevState) => ({ 
        ...prevState,
        isOrderForced: !prevState.isOrderForced,
        isOrderAllowed: !prevState.isOrderForced
      }));
    }
  });

  const [isAppLoading, setAppLoading] = useState(true);

  const [globals, setGlobals] = useState({
    constants: {
      misc: {
        idleTimeout: 1200
      },
      theme: {
        fontSize: 14,
        fontFamily: "Comfortaa",
        borderRadius: 2,
        colorBgBase: "#fff",
        colorPrimary: "#f06440",
        colorError: "#f06440",
        colorSuccess: "#52c41a",
        colorTextBase: "#111",
      }
    },
    strings: {
      offlineTitle: '',
      offlineText: '',
      successTitle: '',
      successText: '',
      orderAppTitle: null,
      calulatorAppTitle: null,
    },
  });

  useEffect(() => {
    getGlobals().then((globals) => {
      WebFont.load({
        google: {
          families: [
            globals.constants.theme.fontFamily
          ]
        }
      });
      setGlobals(globals);
      setState({
        id: uuidv4(),
        createdAt: dayjs(),
        isDebugEnabled: false,
        isOrderForced: false,
        isOrderAllowed: isOrderAllowed(globals),
        numberOfItems: 1,
        items: [{
          id: uuidv4(),
          length: 0,
          width: 0,
          height: 0,
          weight: 0,
        }],
        iataWeight: '0.00',
        weightCategory: globals.constants.weightOptions[0].id,
        pickUpDate: dayjs(),
        pickUpTime: dayjs(),
        sender: {
          contactType: ContactType.Company
        },
        recipient: {
          contactType: ContactType.Company
        },
        billing: {
          subjectType: BillingSubjectType.Sender,
          isOrganisation: false
        },
        paymentMethod: PaymentMethod['Credit Card'],
        phoneIsDefaultContactMethod: true,
        gcAccepted: false,
      });

      // check url query params
      const { success, failure } = Object.fromEntries(new URLSearchParams(window.location.search).entries());
      if (success) {
        setActiveComponent('success');
      } 
      else if (failure) {
        setActiveComponent('failure');
      } 
      else if (!globals.constants.widgetEnabled) {
        setActiveComponent('offline');
      }

      setAppLoading(false);
    });
  }, []);

  return (
    <ConfigProvider 
      theme={{ token: globals.constants.theme }}
      renderEmpty={() => null}
      // @ts-ignore
      getPopupContainer={() => shadowRoot}
    >

      {activeComponent === ActiveComponent.calculator && (
        <Calculator
          state={state}
          globals={globals}
          isAppLoading={isAppLoading}
          setState={setState}
          onSubmit={onCalculatorSubmit} 
        />
      )}

      {activeComponent === ActiveComponent.order && (
        <Order
          state={state}
          globals={globals}
          setState={setState}
        />
      )}
      
      {activeComponent === ActiveComponent.offline && (
        <Card title={globals.strings.calulatorAppTitle ? globals.strings.calulatorAppTitle : null}>
          <Typography.Title level={5} type='secondary' style={{ margin: 0 }}>
            {globals.strings.offlineTitle}
          </Typography.Title>
          <p>{globals.strings.offlineText}</p>
        </Card>
      )}

      {activeComponent === ActiveComponent.success && (
        <Card title={globals.strings.orderAppTitle ? globals.strings.orderAppTitle : null}>
          <Typography.Title level={5} type='success' style={{ margin: 0 }}>
            {globals.strings.successTitle}
          </Typography.Title>
          <p>{globals.strings.successText}</p>
        </Card>
      )}

      {state.isDebugEnabled && (
        <code>{JSON.stringify(state, null, 2)}</code>
      )}
    </ConfigProvider>
  );
};
