import type { Coords, State } from './types';

const apiUrl = process.env.REACT_APP_API_URL

export async function getPaymentStatus(order: State): Promise<string> {
	const response = await fetch(`${apiUrl}/api/get-payment-status?orderId=${order.id}`, {
    method: "GET", 
    headers: { 
			"Content-Type": "application/json",
			// @ts-ignore
			"Authorization": `users API-Key ${globalThis.__courierAPIKey__}`,
		},
  });
	const url = await response.json();
	return url;
}

export async function getPaymentUrl(order: State): Promise<string> {
	const response = await fetch(`${apiUrl}/api/get-payment-url?orderId=${order.id}`, {
    method: "GET", 
    headers: { 
			"Content-Type": "application/json",
			// @ts-ignore
			"Authorization": `users API-Key ${globalThis.__courierAPIKey__}`,
		},
  });
	const url = await response.json();
	return url;
}

export async function createOrder(order: State): Promise<void> {
	await fetch(`${apiUrl}/api/orders`, {
    method: "POST", 
		body: JSON.stringify(order),
    headers: { 
			"Content-Type": "application/json",
			// @ts-ignore
			"Authorization": `users API-Key ${globalThis.__courierAPIKey__}`,
		},
  });
}

export async function updateOrder(order: State): Promise<void> {
	const { id, ...data } = order
	await fetch(`${apiUrl}/api/orders/${id}`, {
    method: "PATCH",
		body: JSON.stringify(data),
    headers: { 
			"Content-Type": "application/json",
			// @ts-ignore
			"Authorization": `users API-Key ${globalThis.__courierAPIKey__}`,
		},
  });
}

export async function getConstants() {
	const response = await fetch(`${apiUrl}/api/globals/constants`, {
    method: "GET", 
    headers: { 
			"Content-Type": "application/json",
			// @ts-ignore
			"Authorization": `users API-Key ${globalThis.__courierAPIKey__}`,
		},
  });
	const data = await response.json();
	return data;
}

export async function getStrings() {
	const response = await fetch(`${apiUrl}/api/globals/strings`, {
    method: "GET", 
    headers: { 
			"Content-Type": "application/json",
			// @ts-ignore
			"Authorization": `users API-Key ${globalThis.__courierAPIKey__}`,
		},
  });
	const data = await response.json();
	return data;
}

export async function getGlobals() {
	const constants = await getConstants();
	const strings = await getStrings();
	return {
		constants,
		strings,
	}
}

export async function getLocationCoords(code: string, city: string): Promise<Coords> {
	const response = await fetch(`${apiUrl}/api/get-location-coords?code=${code}&city=${city}`, {
    method: "GET", 
    headers: { 
			"Content-Type": "application/json",
			// @ts-ignore
			"Authorization": `users API-Key ${globalThis.__courierAPIKey__}`,
		},
  });
	const data = await response.json();
	return data;
}

export async function getLocationsByZipCity(str: string): Promise<any> {
	const response = await fetch(`${apiUrl}/api/get-locations-by-zip-city?str=${str}`, {
    method: "GET", 
    headers: { 
			"Content-Type": "application/json",
			// @ts-ignore
			"Authorization": `users API-Key ${globalThis.__courierAPIKey__}`,
		},
  });
	const data = await response.json();
	if (!Array.isArray(data)) return [];
	return data.map(({ code, city }: any) => `${code} ${city}`);
}

export async function getDrivingDistanceAndDuration(from: Coords, to: Coords): Promise<any> {
	const response = await fetch(`${apiUrl}/api/get-driving-distance-and-duration?fromLat=${from.lat}&fromLon=${from.lon}&toLat=${to.lat}&toLon=${to.lon}`, {
    method: "GET", 
    headers: { 
			"Content-Type": "application/json",
			// @ts-ignore
			"Authorization": `users API-Key ${globalThis.__courierAPIKey__}`,
		},
  });
	const data = await response.json();
	return data;
}

