import { 
  useState 
} from 'react';

import { 
  useIdleTimer 
} from 'react-idle-timer';


export function useIdleTimeout({ onIdle, idleTime = 1 }: any) {
  const idleTimeout = 1000 * idleTime;
  const [isIdle, setIdle] = useState(false)
  
  const handleIdle = () => {
      setIdle(true)
      // ... actions here
  }
  const idleTimer = useIdleTimer({
      timeout: idleTimeout,
      promptBeforeIdle: idleTimeout / 2,
      onPrompt: onIdle,
      onIdle: handleIdle,
      debounce: 500
  })
  return {
      isIdle,
      setIdle,
      idleTimer
  }
}
