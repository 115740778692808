import React, { 
  Dispatch, 
  SetStateAction, 
  useState 
} from 'react';
import dayjs from 'dayjs';
import Divider from 'antd/es/divider' 
import Typography from 'antd/es/typography' 
import Card from 'antd/es/card' 
import Row from 'antd/es/row' 
import Col from 'antd/es/col' 
import Select from 'antd/es/select' 
import Space from 'antd/es/space' 
import Button from 'antd/es/button' 
import Form from 'antd/es/form' 
import Radio from 'antd/es/radio' 
import Modal from 'antd/es/modal';
import Spin from 'antd/es/spin';
import { 
  FormProps 
} from 'antd/es/form'
import { 
  useIdleTimeout 
} from './Common';
import { 
  getLocationsByZipCity 
} from './api';
import { 
  getDeliveryConditions, 
  secondsToTimeStr,
  timeStrToSeconds
} from './utils';
import type { 
  State 
} from './types';

type CalculatorPropTypes = {
  onSubmit: () => void,
  state: State, 
  setState: Dispatch<SetStateAction<State>>,
  isAppLoading: boolean,
  globals: Record<string, any>
}

export default function Calculator({ onSubmit, state, setState, isAppLoading, globals }: CalculatorPropTypes) {
  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm();

  const onFinish: FormProps["onFinish"] = async (values) => {
    setIsLoading(true);
    if (values.from && values.to && values.weightCategory) {
      const data = await getDeliveryConditions(globals, values.from, values.to, values.weightCategory);
      setState({ ...values, ...data });
    }
    setIsLoading(false);
  };

  const [fromOptions, setFromOptions] = useState([]);
  const handleKeyUpFrom = async (event: any) => {
    if (!event.target.value.length) return;
    try {
      const data = await getLocationsByZipCity(event.target.value);
      setFromOptions(data.map((str: string) => ({ value: str, label: str })));
    } catch (err) {
      console.error(err);
    }
  };

  const [toOptions, setToOptions] = useState([]);
  const handleKeyUpTo = async (event: any) => {
    if (!event.target.value.length) return;
    try {
      const data = await getLocationsByZipCity(event.target.value);
      setToOptions(data.map((str: string) => ({ value: str, label: str })));
    } catch (err) {
      console.error(err);
    }
  };

  function recalculateForm() {
    if (form.getFieldValue('from') && form.getFieldValue('to')) {
      form.submit();
    }
  }

  const [isModalOpen, setModalOpen] = useState(false);

  useIdleTimeout({ 
    onIdle: async () => {
      setModalOpen(true);
    }, 
    idleTime: globals.constants.misc.idleTimeout * 60
  });

  return isAppLoading ? (
    <Spin size="large" />
  ) : (
    <>
      <Modal
        open={isModalOpen}
        onCancel={() => {
          setModalOpen(false);
        }}
        okText={globals.strings.recalculatePopupBtnOkText}
        cancelText={globals.strings.recalculatePopupBtnCancelText}
        onOk={async () => {
          await onFinish({
            from: form.getFieldValue('from'), 
            to: form.getFieldValue('to'), 
            weightCategory: form.getFieldValue('weightCategory')
          });
          setModalOpen(false);
        }}
      >
        {globals.strings.recalculatePopupText}
      </Modal>
      <Card title={globals.strings.calulatorAppTitle ? globals.strings.calulatorAppTitle : null}>
        <Form 
          id='calculator'
          validateMessages={{ required: "required" }}
          layout='vertical'
          form={form}
          initialValues={{ 
            weightCategory: globals.constants.weightOptions[0].id,
          }}
          onFinish={onFinish}
          autoComplete="off"
          onValuesChange={async(_, values) => {
            const nextState = { ...values };
            if (nextState.from && nextState.to) {
              Object.assign(nextState, (
                await getDeliveryConditions(
                  globals,
                  values.from, 
                  values.to,
                  values.weightCategory
                )
              ));
            }
            setState(nextState); 
          }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item
                name="from"
                rules={[{ required: true }]}
                label={globals.strings.fromFieldLabel}
                tooltip={globals.strings.fromFieldTooltip}
              >
                <Select 
                  showSearch
                  filterOption={false}
                  notFoundContent={null}
                  defaultActiveFirstOption={false}
                  onChange={recalculateForm}
                  style={{ width: '100%' }}
                  placeholder={globals.strings.fromFieldPlaceholder}
                  onKeyUp={handleKeyUpFrom}
                  options={fromOptions}
                  optionRender={(option) => (
                    <Space>{option.label}</Space>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="to"
                rules={[{ required: true }]}
                label={globals.strings.toFieldLabel}
                tooltip={globals.strings.toFieldTooltip}
              >
                <Select
                  onChange={recalculateForm}
                  showSearch
                  filterOption={false}
                  notFoundContent={null}
                  defaultActiveFirstOption={false}
                  style={{ width: '100%' }}
                  placeholder={globals.strings.fromFieldPlaceholder}
                  onKeyUp={handleKeyUpTo}
                  options={toOptions}
                  optionRender={(option) => (
                    <Space>{option.label}</Space>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Form.Item name="weightCategory">
                <Radio.Group>
                  {globals.constants.weightOptions.map(({ label, id }: any) => (
                    <Radio.Button
                      onChange={recalculateForm}
                      key={id}
                      value={id}>
                      {label}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Button 
                style={{ width: '100%' }}
                type="primary" 
                onClick={onSubmit} 
                loading={isLoading}>
                {isLoading 
                  ? globals.strings.calculatingBtnText
                  : globals.strings.orderBtnText
                }
              </Button>
            </Col>
          </Row>

          {state.distance && state.duration && state.price && (
            <>
              <Divider dashed />
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Form.Item>
                    <Typography.Title level={5} type='success' style={{ margin: 0 }}>
                      {globals.strings.deliveryTimeTitle}:
                    </Typography.Title>
                    <Typography.Title type='success' style={{ margin: 0, letterSpacing: 2 }}>
                      {secondsToTimeStr(state.duration)}
                    </Typography.Title>
                    <small>
                      {globals.strings.deliveryTimeSmallText}:
                      <br />
                      {`${globals.strings.deliveryTimeIfReadyText} `}
                      {dayjs()
                        .hour(18)
                        .minute(0)
                        .subtract(timeStrToSeconds(secondsToTimeStr(state.duration)), 'second')
                        .format('HH:mm')
                      }
                    </small>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <Typography.Title level={5} type='success' style={{ margin: 0 }}>
                      {globals.strings.deliveryPriceTitle}:
                    </Typography.Title>
                    <Typography.Title type='success' style={{ margin: 0 }}>
                      CHF {state.price}
                    </Typography.Title>
                    <small>
                      {globals.strings.deliveryPriceSmallText}
                    </small>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Card>
    </>
  );
}
