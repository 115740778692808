import { Dayjs } from 'dayjs';

export enum ContactType {
  'Company' = 'Company',
  'Mr' = 'Mr',
  'Ms' = 'Ms',
}

export enum BillingSubjectType {
  'Sender' = 'Sender',
  'Recipient' = 'Recipient',
  'Third Parties' = 'Third Parties',
}

export enum PaymentMethod {
  'Credit Card' = 'Credit Card',
  'Invoice' = 'Invoice',
  'Cash' = 'Cash'
}

export enum ItemDimensionPresets {
  'Custom' = 'Custom',
  'Pallet' = 'Pallet',
  'Envelope' = 'Envelope',
}

export type Coords = {
	lon: number;
	lat: number;
}

export type Item = {
  id: string;
  length: number;
  width: number;
  height: number;
  weight: number;
}

export type SenderRecipient = {
  contactType?: ContactType; 
  company?: string;
  firstName?: string;
  lastName?: string;
  street?: string;
  contactName?: string;
  contactTelephone?: string;
}

export type Billing = {
  subjectType?: BillingSubjectType;
  isOrganisation?: boolean;
  referenceForInvoice?: string;
  emailForInvoice?: string;
  contactType?: ContactType; 
  company?: string;
  firstName?: string;
  lastName?: string;
  street?: string;
  contactName?: string;
  zip?: string;
  city?: string;
}

export type State = {
  id?: string;
  createdAt?: Dayjs;
  isDebugEnabled: boolean;
  isOrderForced: boolean;
  isOrderAllowed: boolean;
  from?: string;
  to?: string;
  pickUpDate?: Dayjs;
  pickUpTime?: Dayjs;
  numberOfItems?: number;
  items?: Item[];
  iataWeight?: string;
  weightCategory?: string;
  email?: string;
  phone?: string;
  phoneIsDefaultContactMethod?: boolean;
  useEmailForFreightLetters?: boolean;
  emailForFreightLetters?: string;
  distance?: number; 
  duration?: number;
  price?: string;
  sender?: SenderRecipient;
  recipient?: SenderRecipient;
  billing?: Billing;
  paymentMethod?: PaymentMethod;
  comments?: string;
  gcAccepted?: boolean;
}
