import React from 'react';
import { createRoot } from 'react-dom/client';
import { StyleProvider } from '@ant-design/cssinjs';
import { App } from './App';

const rootNode = document.getElementById('courier');
// @ts-ignore
globalThis.__courierAPIKey__ = rootNode?.dataset.key;

const container = document.createElement('div');
const shadowRoot = rootNode!.attachShadow({ mode: 'open' });
shadowRoot.appendChild(container);

createRoot(container).render(
  <StyleProvider container={shadowRoot}>
    <style>
    {
      `code {
        padding: 5px;
        font-size: 11px;
        line-height: 1.3;
        font-family: monospace;
        position: fixed;
        left: 0;
        top: 0;
        overflow: auto;
        max-height: 100%;
        background: rgba(255,255,255,0.5);
        white-space: pre-wrap;
      }
      *[hidden] {
        display: none;
      }
      .ant-form-item .ant-form-item-explain-error {
        font-size: 11px;
      }
      .ant-steps .ant-steps-item-title {
        font-size: 12px;
      }
      .block .ant-col {
        font-size: 11px;
        line-height: 1.8;
      }
      .block > * > .ant-col {
        padding: 5px 10px;
        border-width: 0 1px 1px 0;
        border-color: white;
        border-style: solid;
        background: rgba(0,0,0, 0.07);
      }`
    }
    </style>
    <App shadowRoot={shadowRoot} />
  </StyleProvider>,
);
