import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { getDrivingDistanceAndDuration, getLocationCoords } from './api';
import { Item } from './types';
dayjs.extend(isBetween)

export function isOrderAllowed(globals: Record<string, any>, extraSeconds?: number): boolean {
  const currentDay = dayjs().day();
  // exclude weekends 
  if (currentDay === 0 || currentDay === 6) {
    return false;
  }

  // exclude non working days
  const datesToExclude = globals.constants.nonWorkingDays;
  if (datesToExclude.map(({ fromDate, toDate }: any) => 
    dayjs().isBetween(fromDate, toDate, 'day', '[)')
  ).includes(true)) {
    return false;
  }

  const startHour = dayjs(globals.constants.workingHours.startTime).hour();
  const finishHour = dayjs(globals.constants.workingHours.finishTime).hour();
  const currentHour = extraSeconds 
    ? dayjs().add(extraSeconds, 'second').hour()
    : dayjs().hour();
  return startHour < currentHour && finishHour > currentHour;
}

export function secondsToTime(seconds: number) {
  const secs = Math.round(seconds);
  const hoursValue = Math.floor(seconds / (60 * 60));
  const hours = hoursValue < 10 ? `0${hoursValue}` : `${hoursValue}`
  const minutesValue = Math.ceil(Math.floor(secs % (60 * 60) / 60) / 10) * 10;
  const minutes = minutesValue < 10 ? `0${minutesValue}` : `${minutesValue}`
  return {
    hours,
    minutes,
  }
}

export function timeStrToSeconds(time: string) {
  const [ hoursStr, minutesStr ] = time.split(":");
  const hours = parseInt(hoursStr);
  const minutes = parseInt(minutesStr);
  const seconds = 60 * minutes + 60 * 60 * hours;
  return seconds;
}

export function secondsToTimeStr(seconds: number) {
  const { hours, minutes } = secondsToTime(seconds);
  return `${hours}:${minutes}`;
}

function executeRules(rules: any[], param: number): boolean {
  const results = rules.map((rule) => {
    const { operator, value } = rule;
    switch (operator) {
      case 'less-or-equal': return param <= value;
      case 'less': return param < value;
      case 'equal': return param === value;
      case 'greater': return param > value;
      case 'greater-or-equal': return param >= value;
      default: throw new Error('Unable to parse rules');
    }
  });
  return !results.includes(false);
}

export function calculateWeightCategory(globals: Record<string, any>, weight: number) {
  for (const opt of globals.constants.weightOptions) {
    if (executeRules(opt.rules, weight)) {
      return opt;
    }
  }
  throw new Error(`Unable to determine category for weight: ${weight} kg`);
}

export function getBasePriceOption(globals: Record<string, any>, distance: number) {
  for (const opt of globals.constants.basePriceOptions) {
    if (executeRules(opt.rules, distance)) {
      return opt;
    }
  }
  throw new Error(`Unable to determine base price option for distance: ${distance} kg`);
}

export function getIataVolumeWeight(item: Item): number {
  const actualWeight = item.weight;
  const iataWeight = item.length * item.width * item.height / 6000;
  const volumeWeight = actualWeight > iataWeight ? actualWeight : iataWeight;
  return volumeWeight;
}

export function calculateIataWeight(items: Item[] = []): string {
  return (items
    .map(getIataVolumeWeight)
    .reduce((a: number, b: number) => a + b, 0)).toFixed(2);
}

export function formatPriceToStr(price: number): string {
  const [sol, dec] = price.toFixed(2).split('.');
  let rounded = `${Math.round(Number(dec) / 5) * 5}`;
  if (rounded.length === 1) rounded = `${rounded}0`;
  return `${sol}.${rounded}`;
}

export function calculatePrice(globals: Record<string, any>, distance: number, weightCategory: string): string {
  const basePrice = parseInt(globals.constants.basePrice);
  const weightOption = globals.constants.weightOptions.find((option: any) => option.id === weightCategory);
  if (!weightOption) throw new Error(`Unable to find weight option for weightCategory ${weightCategory}`);
  const priceOption = getBasePriceOption(globals, distance);

  const price = basePrice + (distance / 1000) * priceOption.value * weightOption.value;
  return formatPriceToStr(price);
}

function locationToCodeCity(location: string) {
  const [zip, ...rest] = location.split(' ');
  const city = rest.join(' ');
  return { zip, city };
}

let cache = new Map();

export async function getDeliveryConditions(globals: Record<string, any>, from: string, to: string, weightCategory: string) {
  const key = `${from}/${to}/${weightCategory}`
  if (cache.has(key)) {
    return cache.get(key);
  }

  const fromCodeCity = locationToCodeCity(from);
  const toCodeCity = locationToCodeCity(to);
  const fromCoords = await getLocationCoords(fromCodeCity.zip, fromCodeCity.city);
  const toCoords = await getLocationCoords(toCodeCity.zip, toCodeCity.city);
  const distanceDurationFromWarehouse = await getDrivingDistanceAndDuration(globals.constants.warehouseCoords, fromCoords);
  const distanceDurationFromPointAToB = await getDrivingDistanceAndDuration(fromCoords, toCoords);
  const price = calculatePrice(globals, distanceDurationFromPointAToB.distance, weightCategory);
  const distance = Number((distanceDurationFromPointAToB.distance).toFixed(2));
  const extraTime = parseInt(globals.constants.misc.deliveryExtraTime) * 60;
  const duration = extraTime + Number((distanceDurationFromWarehouse.duration + distanceDurationFromPointAToB.duration).toFixed(2));
  const result = { price, distance, duration };

  cache.set(key, result);

  return result;
}

export const validation = {
  required: (_: any, value: number | string) => 
    value 
      ? Promise.resolve() 
      : Promise.reject(new Error('required')),

  phone: (_: any, value: string) => 
    (value && value.length > 7 && /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(value)) // eslint-disable-line
      ? Promise.resolve()
      : Promise.reject(new Error('invalid')),

  email: (_: any, value: string) => 
    (value && value.length > 6 && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)) // eslint-disable-line
      ? Promise.resolve()
      : Promise.reject(new Error('invalid')),

  emailNotRequired: (_: any, value: string) => 
    (!value || (value.length > 6 && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value))) // eslint-disable-line
      ? Promise.resolve()
      : Promise.reject(new Error('invalid')),
}
