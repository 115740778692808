import React, { 
  Dispatch, 
  SetStateAction, 
  useEffect, 
  useState 
} from 'react';
import { 
  v4 as uuidv4 
} from 'uuid';
import dayjs from 'dayjs';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import Radio from 'antd/es/radio';
import Steps from 'antd/es/steps';
import Divider from 'antd/es/divider';
import Card from 'antd/es/card';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import Space from 'antd/es/space';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import DatePicker from 'antd/es/date-picker';
import TimePicker from 'antd/es/time-picker';
import InputNumber from 'antd/es/input-number';
import Checkbox from 'antd/es/checkbox';
import Typography from 'antd/es/typography';
import Result from 'antd/es/result';
import Input from 'antd/es/input';
import TextArea from 'antd/es/input/TextArea';
import Modal from 'antd/es/modal';
import { 
  useIdleTimeout 
} from './Common';
import { 
  createOrder,
  getLocationsByZipCity, 
  getPaymentUrl, 
  updateOrder
} from './api';
import { 
  State, 
  ContactType, 
  BillingSubjectType,
  Item,
  PaymentMethod
} from './types';
import { 
  validation, 
  secondsToTimeStr, 
  calculateIataWeight, 
  getDeliveryConditions, 
  calculateWeightCategory,
  isOrderAllowed
} from './utils';


type OrderPropTypes = {
  state: State, 
  setState:  Dispatch<SetStateAction<Partial<State>>>,
  globals: Record<string, any>
}

export default function Order({ state, setState, globals }: OrderPropTypes) {
  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm();

  const [fromOptions, setFromOptions] = useState([]);
  const handleKeyUpFrom = async (event: any) => {
    if (!event.target.value.length) return;
    try {
      const data = await getLocationsByZipCity(event.target.value);
      setFromOptions(data.map((str: string) => ({ value: str, label: str })));
    } catch (err) {
      console.error(err);
    }
  };

  const [toOptions, setToOptions] = useState([]);
  const handleKeyUpTo = async (event: any) => {
    if (!event.target.value.length) return;
    try {
      const data = await getLocationsByZipCity(event.target.value)
      setToOptions(data.map((str: string) => ({ value: str, label: str })));
    } catch (err) {
      console.error(err);
    }
  };

  const [current, setCurrent] = useState(0);
  const next = async() => { 
    try {
      await form.validateFields();
      await updateOrder(state);
      setCurrent(current + 1); 
    } catch (err) {
    }
  };
  const prev = () => { 
    setCurrent(current - 1); 
  };

  const handleOnValuesChange = async(keyValue: Record<string, any>, values: State) => {
    setIsLoading(true);
    const nextState: State = {
      ...state,
      ...values,
      sender: {
        ...state.sender,
        ...values.sender || {},
      },
      recipient: {
        ...state.recipient,
        ...values.recipient || {},
      },
      billing: {
        ...state.billing,
        ...values.billing || {},
      },
    };

    if (nextState.iataWeight) {
      nextState.weightCategory = calculateWeightCategory(globals, Number(nextState.iataWeight)).id;
    }

    if (nextState.billing?.subjectType === BillingSubjectType.Sender) {
      nextState.billing = {
        ...nextState.billing,
        ...nextState.sender,
      }
      nextState.billing.isOrganisation = nextState.sender?.contactType === ContactType.Company;
      
      if (nextState.from) {
        const [zip, ...cityArr] = nextState.from.split(' ');
        const city = cityArr.join(' ');
        nextState.billing.zip = zip;
        nextState.billing.city = city;
      }
    }

    if (nextState.billing?.subjectType === BillingSubjectType.Recipient) {
      nextState.billing = {
        ...nextState.billing,
        ...nextState.recipient,
      }
      nextState.billing.isOrganisation = nextState.recipient?.contactType === ContactType.Company;

      if (nextState.to) {
        const [zip, ...cityArr] = nextState.to.split(' ');
        const city = cityArr.join(' ');
        nextState.billing.zip = zip;
        nextState.billing.city = city;
      }
    }

    if (nextState.billing?.subjectType === BillingSubjectType['Third Parties']) {
      nextState.billing.isOrganisation = nextState.billing?.contactType === ContactType.Company;
    }

    if (('items' in keyValue) || ('from' in keyValue) || ('to' in keyValue)) {
      if (nextState.from && nextState.to && state.weightCategory) {
        const { price, distance, duration } = await getDeliveryConditions(
          globals,
          values.from as string, 
          values.to as string,
          state.weightCategory
        );
        nextState.price = price;
        nextState.distance = distance;
        nextState.duration = duration;
        nextState.isOrderAllowed = state.isOrderForced ? true : isOrderAllowed(globals, duration);
      }
    }
    if (keyValue.numberOfItems) {
      nextState.numberOfItems = keyValue.numberOfItems;
    }

    if (keyValue.items) {
      nextState.numberOfItems = keyValue.items.length;
      form.setFieldValue('numberOfItems', nextState.numberOfItems);
    }
    
    if (nextState.items) {
      nextState.iataWeight = calculateIataWeight(values.items);
    }

    setState(nextState);
    setIsLoading(false);
  };

  const [isModalOpen, setModalOpen] = useState(false);

  useIdleTimeout({ 
    onIdle: async () => {
      setModalOpen(true);
    }, 
    idleTime: globals.constants.misc.idleTimeout * 60
  });

  useEffect(() => {
    setIsLoading(true);
    createOrder(state).then(() => {
      setIsLoading(false);
    });
  }, []);
  
  async function initCheckout() {
    setIsLoading(true);            
    const paymentUrl = await getPaymentUrl(state);
    setIsLoading(false);
    window.location.href = paymentUrl;
  }

  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={() => {
          setModalOpen(false);
        }}
        okText={globals.strings.recalculatePopupBtnOkText}
        cancelText={globals.strings.recalculatePopupBtnCancelText}
        onOk={async () => {
          await handleOnValuesChange(state, state);
          setModalOpen(false);
        }}
      >
        {globals.strings.recalculatePopupText}
      </Modal>
      <Card 
        title={globals.strings.orderAppTitle ? globals.strings.orderAppTitle : null} 
        style={{ width: 880 }}>
        <Form 
          id='order'
          form={form}
          layout='vertical'
          autoComplete="off"
          validateMessages={{ 
            required: globals.strings.fieldValidationRequiredText
          }}
          initialValues={state}
          onValuesChange={handleOnValuesChange}
        >
          <br/>
          <Steps
            progressDot
            current={current}
            items={[
              globals.strings.orderStepOneText,
              globals.strings.orderStepTwoText,
              globals.strings.orderStepThreeText,
              globals.strings.orderStepFourText,
            ].map((str) => ({ key: str, title: str }))}
          />
          <Divider dashed />

          {(current === 0) && (
            <>
              <Typography.Title level={2}>
                {globals.strings.deliveryTitle}
              </Typography.Title>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Form.Item
                    name="from"
                    rules={[{ required: true }]}
                    label={globals.strings.fromFieldLabel}
                    tooltip={globals.strings.fromFieldTooltip}
                  >
                    <Select 
                      showSearch
                      filterOption={false}
                      notFoundContent={null}
                      defaultActiveFirstOption={false}
                      style={{ width: '100%' }}
                      placeholder={globals.strings.fromFieldPlaceholder}
                      onKeyUp={handleKeyUpFrom}
                      options={fromOptions}
                      optionRender={(option) => (
                        <Space>{option.label}</Space>
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="to"
                    rules={[{ required: true }]}
                    label={globals.strings.toFieldLabel}
                    tooltip={globals.strings.toFieldTooltip}
                  >
                    <Select
                      showSearch
                      filterOption={false}
                      notFoundContent={null}
                      defaultActiveFirstOption={false}
                      style={{ width: '100%' }}
                      placeholder={globals.strings.toFieldPlaceholder}
                      onKeyUp={handleKeyUpTo}
                      options={toOptions}
                      optionRender={(option) => (
                        <Space>{option.label}</Space>
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Form.Item
                    name="pickUpDate"
                    rules={[{ required: true }]}
                    label={globals.strings.pickUpDateFieldLabel}
                  >
                    <DatePicker 
                      format={{
                        format: 'DD / MM / YYYY',
                        type: 'mask',
                      }}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="pickUpTime"
                    rules={[{ required: true }]}
                    label={globals.strings.pickUpTimeFieldLabel}
                  >
                    <TimePicker 
                      format={{
                        format: 'HH : mm',
                        type: 'mask',
                      }}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              
              <Typography.Title level={4}>
                {globals.strings.deliveryInfoSubTitle}
              </Typography.Title>
              
              <br/>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Form.Item
                    name="numberOfItems"
                    rules={[{ 
                      validator: validation.required,
                      message: globals.strings.fieldValidationInvalidText
                    }]}
                    label={globals.strings.numberOfItemsFieldLabel}
                  >
                    <InputNumber 
                      min={1}
                      max={50}
                      // defaultValue={state.numberOfItems}
                      // value={state.numberOfItems}
                      style={{ width: '100%' }}
                      onChange={(numberOfItems: number | null) => {
                        if (!numberOfItems) return;
                        let items = structuredClone(form.getFieldValue('items'));
                        if (numberOfItems === items.length) return;
                        if (numberOfItems < items.length)
                          items = items.slice(0, numberOfItems);
                        if (numberOfItems > items.length) {
                          let itemsToAdd = numberOfItems - items.length;
                          for (let i = 0; i < itemsToAdd; i++) {
                            items.push({ id: uuidv4(), length: 0, width: 0, height: 0, weight: 0 });
                          }
                        } 
                        const iataWeight = calculateIataWeight(items);
                        form.setFieldsValue({ items, iataWeight });
                        setState({ items, iataWeight });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={globals.strings.useCustomDimensionsLabel}>   
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col>
                        <Button 
                          type="default" 
                          onClick={() => {
                            const items = form.getFieldValue('items');
                            for (let idx = 0; idx < items.length; idx++) {
                              items[idx].length = globals.constants.palletDimensions.length;
                              items[idx].width = globals.constants.palletDimensions.width;
                              items[idx].weight = globals.constants.palletDimensions.weight;
                            }
                            const iataWeight = calculateIataWeight(items);
                            form.setFieldsValue({ items, iataWeight });
                            setState({ items, iataWeight });
                          }}
                        >
                          {globals.strings.useCustomDimensionsPalletBtnText}
                        </Button>
                      </Col>
                      <Col>
                        <Button 
                          type="default" 
                          onClick={() => {
                            const items = form.getFieldValue('items');
                            for (let idx = 0; idx < items.length; idx++) {
                              items[idx].length = globals.constants.envelopeDimensions.length;
                              items[idx].width = globals.constants.envelopeDimensions.width;
                            }
                            const iataWeight = calculateIataWeight(items);
                            form.setFieldsValue({ items, iataWeight });
                            setState({ items, iataWeight });
                          }}
                        >
                          {globals.strings.useCustomDimensionsEnvelopeBtnText}
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>

              <Form.List name="items">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map((field, index) => (
                        <div key={index}>
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col span={5}>
                              <Form.Item 
                                name={[field.name, 'length']}
                                fieldKey={[field.fieldKey!, 'length']}
                                rules={[{ 
                                  validator: validation.required, 
                                  message: globals.strings.fieldValidationInvalidText
                                }]}
                                label={globals.strings.itemLengthFieldLabel}
                              >
                                <InputNumber 
                                  min={0}
                                  max={240}
                                  addonAfter="cm" 
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item 
                                name={[field.name, 'width']}
                                fieldKey={[field.fieldKey!, 'width']}
                                rules={[{ 
                                  validator: validation.required, 
                                  message: globals.strings.fieldValidationInvalidText
                                }]}
                                label={globals.strings.itemWidthFieldLabel}
                              >
                                <InputNumber 
                                  min={0}
                                  max={120}
                                  addonAfter="cm" 
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item 
                                name={[field.name, 'height']}
                                fieldKey={[field.fieldKey!, 'height']}
                                rules={[{ 
                                  validator: validation.required,
                                  message: globals.strings.fieldValidationInvalidText
                                }]}
                                label={globals.strings.itemHeightFieldLabel}
                              >
                                <InputNumber 
                                  min={0}
                                  max={120}
                                  addonAfter="cm" 
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item 
                                name={[field.name, 'weight']}
                                fieldKey={[field.fieldKey!, 'weight']}
                                rules={[{ 
                                  validator: validation.required,
                                  message: globals.strings.fieldValidationInvalidText
                                }]}
                                label={globals.strings.itemWeightFieldLabel}
                              >
                                <InputNumber
                                  min={0}
                                  max={800}
                                  addonAfter="kg"
                                />
                              </Form.Item>
                            </Col>
                            <Col>
                              <Button
                                style={{ marginTop: 30 }}
                                type='default'
                                shape="circle"
                                icon={<DeleteOutlined />}
                                onClick={() => remove(field.name)}
                              />
                            </Col>
                          </Row>
                        </div>
                      ))}
                      
                      <br/>

                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={15}>
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col>
                              <Button
                                type='default'
                                icon={<PlusOutlined/>}
                                onClick={() => add({ id: uuidv4(), length: 0, width: 0, height: 0, weight: 0 })}
                              >
                                {globals.strings.addNewItemBtnText}
                              </Button>              
                            </Col>
                            <Col>
                              <Button
                                type='default'
                                onClick={() => add({ id: uuidv4(), ...globals.constants.palletDimensions })}
                              >
                                {globals.strings.addNewPalletItemBtnText}
                              </Button>          
                            </Col>
                            <Col>
                              <Button
                                type='default'
                                onClick={() => add({ id: uuidv4(), ...globals.constants.envelopeDimensions })}
                              >
                                {globals.strings.addNewEnvelopeItemBtnText}
                              </Button>        
                            </Col>
                          </Row>
                        </Col>
                        <Col span={9}>
                          <Typography.Text type='success' style={{ fontSize: 20, lineHeight: 1.9 }}>
                            {globals.strings.iataWeightText}: {state.iataWeight} kg
                          </Typography.Text>
                          <br/>
                          <small>
                            {globals.strings.iataWeightSmallText}
                          </small>
                        </Col>
                      </Row>
                    </>
                  );
                }}
              </Form.List>

              {!state.isOrderAllowed && (
                <>
                  <Divider dashed />
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={24}>
                      <Typography.Title level={4}>
                        {globals.strings.offlineCheckoutTitle}
                      </Typography.Title>
                      <p>
                        {globals.strings.offlineCheckoutText}
                      </p>
                    </Col>
                  </Row>
                </>
              )}

              {state.duration && state.pickUpTime && (
                <>
                  <Divider dashed />
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={12}>
                      <Row>
                        <Col span={12}>
                          <Typography.Text style={{ fontSize: 16, lineHeight: 1.9 }}>
                            {globals.strings.summaryDurationOfDeliveryText}
                          </Typography.Text>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                          <Typography.Text type='success' style={{ fontSize: 20 }}>
                            {secondsToTimeStr(state.duration)}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={14}>
                          <Typography.Text style={{ fontSize: 16, lineHeight: 1.9 }}>
                            {globals.strings.summaryEstimatedDeliveryTimeText}
                          </Typography.Text>
                        </Col>
                        <Col span={10} style={{ textAlign: 'right' }}>
                          <Typography.Text type='success' style={{ fontSize: 20 }}>
                            {
                              dayjs(state.pickUpTime)
                                .add(state.duration, 'second')
                                .format('HH : mm')
                            }  {` ${globals.strings.summaryEstimatedDeliveryTimeTillText} `}  {
                              dayjs(state.pickUpTime)
                                .add(state.duration, 'second')
                                .add(1, 'hour')
                                .format('HH : mm')
                            }
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
              
              {state.price && (
                <>
                  <br />
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={12}>
                      <Typography.Text style={{ fontSize: 16, lineHeight: 3.2 }}>
                        {globals.strings.summaryPriceInclVATText}
                      </Typography.Text>
                      <br />
                      <small>
                        {globals.strings.summaryPriceSmallText}
                      </small>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                      <Typography.Text type='success' style={{ fontSize: 30 }}>
                        CHF {state.price}
                      </Typography.Text>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}

          {(current === 1) && (
            <>
              <Typography.Title level={2}>
                {globals.strings.addressesTitle}
              </Typography.Title>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Form.Item
                    name="pickUpDate"
                    rules={[{ required: true }]}
                    label={globals.strings.pickUpDateFieldLabel}
                  >
                    <DatePicker 
                      format={{
                        format: 'DD / MM / YYYY',
                        type: 'mask',
                      }}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="pickUpTime"
                    rules={[{ required: true }]}
                    label={globals.strings.pickUpTimeFieldLabel}
                  >
                    <TimePicker 
                      format={{
                        format: 'HH : mm',
                        type: 'mask',
                      }}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Form.Item
                    name="phone"
                    rules={[{ 
                      validator: validation.phone,
                      message: globals.strings.fieldValidationInvalidText
                    }]}
                    label={globals.strings.phoneFieldLabel}
                  >
                    <Input
                      autoComplete="nope"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                  <Form.Item name="phoneIsDefaultContactMethod" valuePropName="checked">
                    <Checkbox>
                      {globals.strings.phoneIsDefaultContactMethodFieldLabel}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="email"
                    rules={[{ 
                      validator: validation.email,
                      message: globals.strings.fieldValidationInvalidText
                    }]}
                    label={globals.strings.emailFieldLabel}
                  >
                    <Input
                      autoComplete="nope"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Typography.Title level={4}>
                    {globals.strings.pickUpLocationSubTitle}
                  </Typography.Title>
                  <br/>
                  <Form.Item name={['sender', 'contactType']}>   
                    <Radio.Group>
                      <Radio value={ContactType.Company}>{globals.strings.contactTypeFieldCompanyOption}</Radio>
                      <Radio value={ContactType.Mr}>{globals.strings.contactTypeFieldMrOption}</Radio>
                      <Radio value={ContactType.Ms}>{globals.strings.contactTypeFieldMsOption}</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    hidden={state.sender?.contactType !== ContactType.Company}
                    rules={[{ 
                      required: state.sender?.contactType === ContactType.Company
                    }]}
                    name={['sender', 'company']}
                    label={globals.strings.companyFieldLabel}
                  >
                    <Input autoComplete="nope" style={{ width: '100%' }} />
                  </Form.Item>
                  <Row 
                    hidden={state.sender?.contactType === ContactType.Company}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  >
                    <Col span={12}>
                      <Form.Item
                        rules={[{ required: state.sender?.contactType !== ContactType.Company }]}
                        name={['sender', 'firstName']}
                        label={globals.strings.firstNameFieldLabel}
                      >
                        <Input autoComplete="nope" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        rules={[{ required: state.sender?.contactType !== ContactType.Company }]}
                        name={['sender', 'lastName']}
                        label={globals.strings.lastNameFieldLabel}
                      >
                        <Input autoComplete="nope" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    rules={[{ required: true }]}
                    name={['sender', 'street']}
                    label={globals.strings.streetFieldLabel}
                  >
                    <Input autoComplete="nope" style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    name="from"
                    rules={[{ required: true }]}
                    label={globals.strings.zipCityFieldLabel}
                  >
                    <Select 
                      disabled
                      showSearch
                      filterOption={false}
                      notFoundContent={null}
                      defaultActiveFirstOption={false}
                      style={{ width: '100%' }}
                      onKeyUp={handleKeyUpFrom}
                      options={fromOptions}
                      optionRender={(option) => (
                        <Space>{option.label}</Space>
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true }]}
                    name={['sender', 'contactName']}
                    label={globals.strings.contactPersonAtPickupFieldLabel}
                  >
                    <Input autoComplete="nope" style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    rules={[{ 
                      validator: validation.phone,
                      message: globals.strings.fieldValidationInvalidText
                    }]}
                    name={['sender', 'contactTelephone']}
                    label={globals.strings.contactTelephoneFieldLabel}
                  >
                    <Input autoComplete="nope" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Typography.Title level={4}>
                    {globals.strings.deliveryLocationSubTitle}
                  </Typography.Title>
                  <br/>
                  <Form.Item name={['recipient', 'contactType']}>   
                    <Radio.Group>
                      <Radio value={ContactType.Company}>{globals.strings.contactTypeFieldCompanyOption}</Radio>
                      <Radio value={ContactType.Mr}>{globals.strings.contactTypeFieldMrOption}</Radio>
                      <Radio value={ContactType.Ms}>{globals.strings.contactTypeFieldMsOption}</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    hidden={state.recipient?.contactType !== ContactType.Company}
                    rules={[{ 
                      required: state.recipient?.contactType === ContactType.Company
                    }]}
                    name={['recipient', 'company']}
                    label={globals.strings.companyFieldLabel}
                  >
                    <Input autoComplete="nope" style={{ width: '100%' }} />
                  </Form.Item>
                  <Row 
                    hidden={state.recipient?.contactType === ContactType.Company}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  >
                  <Col span={12}>
                    <Form.Item
                        rules={[{ required: state.recipient?.contactType !== ContactType.Company }]}
                        name={['recipient', 'firstName']}
                        label={globals.strings.firstNameFieldLabel}
                      >
                        <Input autoComplete="nope" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        rules={[{ required: state.recipient?.contactType !== ContactType.Company }]}
                        name={['recipient', 'lastName']}
                        label={globals.strings.lastNameFieldLabel}
                      >
                        <Input autoComplete="nope" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    rules={[{ required: true }]}
                    name={['recipient', 'street']}
                    label={globals.strings.streetFieldLabel}
                  >
                    <Input autoComplete="nope" style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    name="to"
                    rules={[{ required: true }]}
                    label={globals.strings.zipCityFieldLabel}
                   >
                    <Select 
                      disabled
                      showSearch
                      filterOption={false}
                      notFoundContent={null}
                      defaultActiveFirstOption={false}
                      style={{ width: '100%' }}
                      onKeyUp={handleKeyUpTo}
                      options={fromOptions}
                      optionRender={(option) => (
                        <Space>{option.label}</Space>
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true }]}
                    name={['recipient', 'contactName']}
                    label={globals.strings.contactPersonAtDeliveryFieldLabel}
                  >
                    <Input autoComplete="nope" style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    rules={[{ 
                      validator: validation.phone,
                      message: globals.strings.fieldValidationInvalidText
                    }]}
                    name={['recipient', 'contactTelephone']}
                    label={globals.strings.contactTelephoneFieldLabel}
                  >
                    <Input autoComplete="nope" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
              
              {state.duration && state.pickUpTime && (
                <>
                  <Divider dashed />
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={12}>
                      <Row>
                        <Col span={12}>
                          <Typography.Text style={{ fontSize: 16, lineHeight: 1.9 }}>
                            {globals.strings.summaryDurationOfDeliveryText}
                          </Typography.Text>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                          <Typography.Text type='success' style={{ fontSize: 20 }}>
                            {secondsToTimeStr(state.duration)}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={14}>
                          <Typography.Text style={{ fontSize: 16, lineHeight: 1.9 }}>
                            {globals.strings.summaryEstimatedDeliveryTimeText}
                          </Typography.Text>
                        </Col>
                        <Col span={10} style={{ textAlign: 'right' }}>
                          <Typography.Text type='success' style={{ fontSize: 20 }}>
                            {
                              dayjs(state.pickUpTime)
                                .add(state.duration, 'second')
                                .format('HH : mm')
                            }  {` ${globals.strings.summaryEstimatedDeliveryTimeTillText} `}  {
                              dayjs(state.pickUpTime)
                                .add(state.duration, 'second')
                                .add(1, 'hour')
                                .format('HH : mm')
                            }
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}

              {state.price && (
                <>
                  <br />
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={12}>
                      <Typography.Text style={{ fontSize: 16, lineHeight: 3.2 }}>
                        {globals.strings.summaryPriceInclVATText}
                      </Typography.Text>
                      <br />
                      <small>
                        {globals.strings.summaryPriceSmallText}
                      </small>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                      <Typography.Text type='success' style={{ fontSize: 30 }}>
                        CHF {state.price}
                      </Typography.Text>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
          
          {(current === 2) && (
            <>
              <Typography.Title level={2}>
                {globals.strings.detailsForInvoiceTitle}
              </Typography.Title>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Form.Item
                    name="email"
                    rules={[{ required: true }, { 
                      validator: validation.email,
                      message: globals.strings.fieldValidationInvalidText
                    }]}
                    label={globals.strings.emailFieldLabel}
                  >
                    <Input
                      autoComplete="nope"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ paddingTop: 34 }}>
                  <Form.Item name="useEmailForFreightLetters" valuePropName="checked">
                    <Checkbox>
                      {globals.strings.useEmailForFreightLettersFieldLabel}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} hidden={state.useEmailForFreightLetters}>
                <Col span={12}>
                  <Form.Item
                    name="emailForFreightLetters"
                    rules={state.useEmailForFreightLetters ? [] : [{ 
                      validator: validation.email,
                      message: globals.strings.fieldValidationInvalidText
                    }]}
                    label={globals.strings.emailForFreightLettersFieldLabel}
                  >
                    <Input
                      autoComplete="nope"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                  <Typography.Title level={4}>
                    {globals.strings.billingInfoSubTitle}
                  </Typography.Title>
                  <br/>
                  <Form.Item name={['billing', 'subjectType']}>   
                    <Radio.Group>
                      <Radio value={BillingSubjectType.Sender}>{globals.strings.subjectTypeFieldSenderOption}</Radio>
                      <Radio value={BillingSubjectType.Recipient}>{globals.strings.subjectTypeFieldRecipientOption}</Radio>
                      <Radio value={BillingSubjectType['Third Parties']}>{globals.strings.subjectTypeFieldThirdPartiesOption}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Form.Item
                    name={['billing', 'referenceForInvoice']}
                    label={globals.strings.referenceForInvoiceFieldLabel}
                  >
                    <Input autoComplete="nope" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={['billing', 'emailForInvoice']}
                    rules={[{ 
                      validator: validation.emailNotRequired,
                      message: globals.strings.fieldValidationInvalidText
                    }]}
                    label={globals.strings.emailForInvoiceFieldLabel}
                  >
                    <Input autoComplete="nope" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} hidden={state.billing?.subjectType !== BillingSubjectType['Third Parties']}>
                <Col span={24}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={24}>
                      <Form.Item name={['billing', 'contactType']}>   
                        <Radio.Group>
                          <Radio value={ContactType.Company}>{globals.strings.contactTypeFieldCompanyOption}</Radio>
                          <Radio value={ContactType.Mr}>{globals.strings.contactTypeFieldMrOption}</Radio>
                          <Radio value={ContactType.Ms}>{globals.strings.contactTypeFieldMsOption}</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} hidden={state.billing?.contactType === ContactType.Company}>
                    <Col span={12}>
                      <Form.Item
                        name={['billing', 'firstName']}
                        rules={[{ 
                          required: (
                            state.billing?.subjectType === BillingSubjectType['Third Parties'] &&
                            state.billing?.contactType !== ContactType.Company
                          )
                        }]}
                        label={globals.strings.firstNameFieldLabel}
                      >
                        <Input autoComplete="nope" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={['billing', 'lastName']}
                        rules={[{ 
                          required: (
                            state.billing?.subjectType === BillingSubjectType['Third Parties'] &&
                            state.billing?.contactType !== ContactType.Company
                          )
                        }]}
                        label={globals.strings.lastNameFieldLabel}
                      >
                        <Input autoComplete="nope" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} hidden={state.billing?.contactType !== ContactType.Company}>
                    <Col span={24}>
                      <Form.Item
                        name={['billing', 'company']}
                        rules={[{ 
                          required: (
                            state.billing?.subjectType === BillingSubjectType['Third Parties'] &&
                            state.billing?.contactType === ContactType.Company
                          )
                        }]}
                        label={globals.strings.companyFieldLabel}
                      >
                        <Input autoComplete="nope" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} hidden={state.billing?.contactType !== ContactType.Company}>
                    <Col span={24}>
                      <Form.Item
                        name={['billing', 'contactName']}
                        label={globals.strings.contactNameFieldLabel}
                      >
                        <Input autoComplete="nope" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={8}>
                      <Form.Item
                        name={['billing', 'zip']}
                        rules={[{ required: state.billing?.subjectType === BillingSubjectType['Third Parties'] }]}
                        label={globals.strings.zipFieldLabel}
                      >
                        <Input autoComplete="nope" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={['billing', 'city']}
                        rules={[{ required: state.billing?.subjectType === BillingSubjectType['Third Parties'] }]}
                        label={globals.strings.cityFieldLabel}
                      >
                        <Input autoComplete="nope" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={['billing', 'street']}
                        rules={[{ required: state.billing?.subjectType === BillingSubjectType['Third Parties'] }]}
                        label={globals.strings.streetFieldLabel}
                      >
                        <Input autoComplete="nope" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              
              {state.isOrderAllowed && (
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={24}>
                    <Typography.Title level={4}>
                      {globals.strings.paymentMethodSubTitle}
                    </Typography.Title>
                    <br/>
                    <Form.Item name='paymentMethod'>   
                      <Radio.Group>
                        {state.billing?.isOrganisation ? (
                          <>
                            <Radio style={{ lineHeight: 2.4 }} value={PaymentMethod['Credit Card']}>
                              <span style={{ width: 100, display: 'inline-block' }}>{globals.strings.paymentMethodFieldCreditCardOption}</span> 
                              <small>{globals.strings.paymentMethodFieldCreditCardText}</small>
                            </Radio>
                            <Radio style={{ lineHeight: 2.4 }} value={PaymentMethod['Invoice']}>
                              <span style={{ width: 100, display: 'inline-block' }}>{globals.strings.paymentMethodFieldInvoiceOption}</span> 
                              <small>{globals.strings.paymentMethodFieldInvoiceText}</small>
                            </Radio>
                          </>
                        ) : (
                          <>
                            <Radio style={{ lineHeight: 2.4 }} value={PaymentMethod['Credit Card']}>
                              <span style={{ width: 100, display: 'inline-block' }}>{globals.strings.paymentMethodFieldCreditCardOption}</span> 
                              <small>{globals.strings.paymentMethodFieldCreditCardText}</small>
                            </Radio>
                            <Radio style={{ lineHeight: 2.4 }} value={PaymentMethod['Cash']}>
                              <span style={{ width: 100, display: 'inline-block' }}>{globals.strings.paymentMethodFieldCashOption}</span> 
                              <small>{globals.strings.paymentMethodFieldCashText}</small>
                            </Radio>
                          </>
                        )}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                  <Typography.Title level={4}>
                    {globals.strings.generalCommentsSubTitle}
                  </Typography.Title>
                  <br/>
                  <Form.Item name={'comments'}>
                    <TextArea style={{ minHeight: 92 }} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                  <Form.Item 
                    rules={[{ 
                      validator: validation.required,
                      message: globals.strings.fieldValidationInvalidText
                    }]} 
                    name="gcAccepted" 
                    valuePropName="checked"
                  >
                    <Checkbox>
                      {globals.strings.cgAcceptedFieldLabel}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              {state.duration && state.pickUpTime && (
                <>
                  <Divider dashed />
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={12}>
                      <Row>
                        <Col span={12}>
                          <Typography.Text style={{ fontSize: 16, lineHeight: 1.9 }}>
                            {globals.strings.summaryDurationOfDeliveryText}
                          </Typography.Text>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                          <Typography.Text type='success' style={{ fontSize: 20 }}>
                            {secondsToTimeStr(state.duration)}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={14}>
                          <Typography.Text style={{ fontSize: 16, lineHeight: 1.9 }}>
                            {globals.strings.summaryEstimatedDeliveryTimeText}
                          </Typography.Text>
                        </Col>
                        <Col span={10} style={{ textAlign: 'right' }}>
                          <Typography.Text type='success' style={{ fontSize: 20 }}>
                            {
                              dayjs(state.pickUpTime)
                                .add(state.duration, 'second')
                                .format('HH : mm')
                            }  {` ${globals.strings.summaryEstimatedDeliveryTimeTillText} `}  {
                              dayjs(state.pickUpTime)
                                .add(state.duration, 'second')
                                .add(1, 'hour')
                                .format('HH : mm')
                            }
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}

              {state.price && (
                <>
                  <br />
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={12}>
                      <Typography.Text style={{ fontSize: 16, lineHeight: 3.2 }}>
                        {globals.strings.summaryPriceInclVATText}
                      </Typography.Text>
                      <br />
                      <small>
                        {globals.strings.summaryPriceSmallText}
                      </small>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                      <Typography.Text type='success' style={{ fontSize: 30 }}>
                        CHF {state.price}
                      </Typography.Text>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}

          {(current === 3) && (
            <>
              <Typography.Title level={2}>
                {globals.strings.yourOrderTitle}
              </Typography.Title>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12} className='block'>
                  <Typography.Title level={4}>
                    {globals.strings.yourDeliverySubTitle}
                  </Typography.Title>
                  <Row>
                    <Col span={12}>{globals.strings.fromFieldLabel}</Col>
                    <Col span={12}><strong>{state.from}</strong></Col>
                  </Row>
                  <Row>
                    <Col span={12}>{globals.strings.toFieldLabel}</Col>
                    <Col span={12}><strong>{state.to}</strong></Col>
                  </Row>
                  <Row>
                    <Col span={12}>{globals.strings.pickUpDateFieldLabel}</Col>
                    <Col span={12}><strong>{state.pickUpDate ? `${dayjs(state.pickUpDate).format('DD/MM/YYYY')}` : ''}</strong></Col>
                  </Row>
                  <Row>
                    <Col span={12}>{globals.strings.pickUpTimeFieldLabel}</Col>
                    <Col span={12}><strong>{state.pickUpTime ? `${dayjs(state.pickUpTime).format('HH:mm')}` : ''}</strong></Col>
                  </Row>
                </Col>
                <Col span={12} className='block'>
                  <Typography.Title level={4}>
                    {globals.strings.pickUpLocationSubTitle}
                  </Typography.Title>
                  {state.sender?.contactType === ContactType.Company ? (
                    <Row>
                      <Col span={12}>{globals.strings.companyFieldLabel}</Col>
                      <Col span={12}><strong>{state.sender.company}</strong></Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col span={12}>{globals.strings.contactNameFieldLabel}</Col>
                      <Col span={12}><strong>{state.sender?.firstName} {state.sender?.lastName}</strong></Col>
                    </Row>
                  )}
                  <Row>
                    <Col span={12}>{globals.strings.streetFieldLabel}</Col>
                    <Col span={12}><strong>{state.sender?.street}</strong></Col>
                  </Row>
                  <Row>
                    <Col span={12}>{globals.strings.zipCityFieldLabel}</Col>
                    <Col span={12}><strong>{state.from}</strong></Col>
                  </Row>
                  <Row>
                    <Col span={12}>{globals.strings.contactTelephoneFieldLabel}</Col>
                    <Col span={12}><strong>{state.sender?.contactTelephone}</strong></Col>
                  </Row>
                  <Row>
                    <Col span={12}>{globals.strings.contactText}</Col>
                    <Col span={12}><strong>{state.sender?.contactName}</strong></Col>
                  </Row>
                </Col>
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12} className='block'>
                  <Typography.Title level={4}>
                    {globals.strings.infoAboutDeliverySubTitle}
                  </Typography.Title>
                  <Row>
                    <Col span={12}>{globals.strings.numberOfItemsFieldLabel}</Col>
                    <Col span={12}><strong>{state.numberOfItems}</strong></Col>
                  </Row>
                  <Row>
                    <Col span={12}>{globals.strings.measurementsText}</Col>
                    <Col span={12}>
                      {state.items?.map((item: Item) => (
                        <Row key={item.id}>
                          <Col span={6}><strong>{item.length}cm</strong></Col>
                          <Col span={6}><strong>{item.width}cm</strong></Col>
                          <Col span={6}><strong>{item.height}cm</strong></Col>
                          <Col span={6}><strong>{item.weight}kg</strong></Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </Col>
                <Col span={12} className='block'>
                  <Typography.Title level={4}>
                    {globals.strings.deliveryLocationSubTitle}
                  </Typography.Title>
                  {state.recipient?.contactType === ContactType.Company ? (
                    <Row>
                      <Col span={12}>{globals.strings.companyFieldLabel}</Col>
                      <Col span={12}><strong>{state.recipient.company}</strong></Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col span={12}>{globals.strings.contactNameFieldLabel}</Col>
                      <Col span={12}><strong>{state.recipient?.firstName} {state.recipient?.lastName}</strong></Col>
                    </Row>
                  )}
                  <Row>
                    <Col span={12}>{globals.strings.streetFieldLabel}</Col>
                    <Col span={12}><strong>{state.recipient?.street}</strong></Col>
                  </Row>
                  <Row>
                    <Col span={12}>{globals.strings.zipCityFieldLabel}</Col>
                    <Col span={12}><strong>{state.to}</strong></Col>
                  </Row>
                  <Row>
                    <Col span={12}>{globals.strings.contactTelephoneFieldLabel}</Col>
                    <Col span={12}><strong>{state.recipient?.contactTelephone}</strong></Col>
                  </Row>
                  <Row>
                    <Col span={12}>{globals.strings.contactText}</Col>
                    <Col span={12}><strong>{state.recipient?.contactName}</strong></Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12} className='block'>
                  <Typography.Title level={4}>
                    {globals.strings.commentsSubTitle}
                  </Typography.Title>
                  <Row>
                    <Col span={24} style={{ minHeight: 92 }}>{state.comments}</Col>
                  </Row>
                </Col>
                <Col span={12} className='block'>
                  <Typography.Title level={4}>
                    {globals.strings.billingAddressSubTitle}
                  </Typography.Title>
                  {state.billing?.contactType === ContactType.Company ? (
                    <Row>
                      <Col span={12}>{globals.strings.companyFieldLabel}</Col>
                      <Col span={12}><strong>{state.billing.company}</strong></Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col span={12}>{globals.strings.contactNameFieldLabel}</Col>
                      <Col span={12}><strong>{state.billing?.firstName} {state.billing?.lastName}</strong></Col>
                    </Row>
                  )}
                  <Row>
                    <Col span={12}>{globals.strings.streetFieldLabel}</Col>
                    <Col span={12}><strong>{state.billing?.street}</strong></Col>
                  </Row>
                  <Row>
                    <Col span={12}>{globals.strings.zipCityFieldLabel}</Col>
                    <Col span={12}><strong>{state.billing?.zip}</strong> <strong>{state.billing?.city}</strong></Col>
                  </Row>
                  {state.billing?.referenceForInvoice && (
                    <Row>
                      <Col span={12}>{globals.strings.referenceForInvoiceFieldLabel}</Col>
                      <Col span={12}><strong>{state.billing?.referenceForInvoice}</strong></Col>
                    </Row>
                  )}
                  {state.billing?.emailForInvoice && (
                    <Row>
                      <Col span={12}>{globals.strings.emailForInvoiceFieldLabel}</Col>
                      <Col span={12}><strong>{state.billing?.emailForInvoice}</strong></Col>
                    </Row>
                  )}
                </Col>
              </Row>

              {state.duration && state.pickUpTime && (
                <>
                  <Divider dashed />
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={12}>
                      <Row>
                        <Col span={12}>
                          <Typography.Text style={{ fontSize: 16, lineHeight: 1.9 }}>
                            {globals.strings.summaryDurationOfDeliveryText}
                          </Typography.Text>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                          <Typography.Text type='success' style={{ fontSize: 20 }}>
                            {secondsToTimeStr(state.duration)}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={14}>
                          <Typography.Text style={{ fontSize: 16, lineHeight: 1.9 }}>
                            {globals.strings.summaryEstimatedDeliveryTimeText}
                          </Typography.Text>
                        </Col>
                        <Col span={10} style={{ textAlign: 'right' }}>
                          <Typography.Text type='success' style={{ fontSize: 20 }}>
                            {
                              dayjs(state.pickUpTime)
                                .add(state.duration, 'second')
                                .format('HH : mm')
                            }  {` ${globals.strings.summaryEstimatedDeliveryTimeTillText} `}  {
                              dayjs(state.pickUpTime)
                                .add(state.duration, 'second')
                                .add(1, 'hour')
                                .format('HH : mm')
                            }
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
              {state.price && (
                <>
                  <br />
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={12}>
                      <Typography.Text style={{ fontSize: 16, lineHeight: 3.2 }}>
                        {globals.strings.summaryPriceInclVATText}
                      </Typography.Text>
                      <br />
                      <small>
                        {globals.strings.summaryPriceSmallText}
                      </small>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                      <Typography.Text type='success' style={{ fontSize: 30 }}>
                        CHF {state.price}
                      </Typography.Text>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}

          {(current === 4) && (
            <Result
              status="success"
              title={globals.strings.successMessageTitle}
              subTitle={globals.strings.successMessageText}
            />
          )}
          
          <Divider dashed />

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={(current === 0 || current === 4) ? 24 : 12}>
              {current > 0 && (
                <Button style={{ width: '100%',  }} onClick={prev}>
                  {globals.strings.previousBtnText}
                </Button>
              )}
            </Col>
            <Col span={(current === 0 || current === 4) ? 24 : 12}>
              
              {(current < 3 || (current === 3 && state.paymentMethod !== 'Credit Card')) && (
                <Button style={{ width: '100%' }} type="primary" onClick={next} disabled={isLoading}>
                  {globals.strings.nextBtnText}
                </Button>
              )} 
              
              {(current === 3 && state.paymentMethod === 'Credit Card') && (
                <Button style={{ width: '100%' }} type="primary" onClick={initCheckout}>
                  {globals.strings.checkoutBtnText || 'Checkout'}
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
}
